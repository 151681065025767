<template>
    <styled-dialog
        value="value"
        custom-content-class="agency-agreement-modal"
        :width="10000">
        <template #heading>
            <div class="close-btn">
                <a @click="$emit('close')">
                    <icon
                        name="close"
                        color="white"
                        size="1.2rem" />
                </a>
            </div>

            <slot name="intro" />
        </template>

        <template>
            <slot name="content">
                <div class="content" />
            </slot>
        </template>

        <template
            v-if="withFooterActions"
            #actions>
            <v-form
                @submit.prevent="submitAgencyAgreement">
                <v-checkbox
                    v-model="readAndAgreed"
                    class="styled-checkbox mb-4"
                    color="primary"
                    :label="`I have read and agree to the ${name}.`" />

                <div class="form-button">
                    <v-btn
                        flat
                        class="blue-btn"
                        color="white"
                        type="submit"
                        :disabled="!readAndAgreed">
                        ACCEPT AND APPLY
                    </v-btn>
                </div>
            </v-form>
        </template>
    </styled-dialog>
</template>

<script>

import StyledDialog from '@/components/globals/StyledDialog';
import Icon from '@/components/globals/Icon.vue';

export default {
    components: {
        Icon,
        StyledDialog,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        withFooterActions: {
            type: [Boolean, Number],
            required: true
        },
        name: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            readAndAgreed: false
        };
    },
    methods: {
        submitAgencyAgreement() {
            if (!this.readAndAgreed) {
                return false;
            }

            this.$emit('signedTheAgreement', this.readAndAgreed);
        }
    },
};
</script>

<style lang="scss">
.agency-agreement-modal {
    .dialog {
        height: 100%;
        overflow: auto;
    }

    .dialog .heading {
        flex-direction: column;
        height: auto !important;
        padding: 0 !important;
        border-bottom: none !important;
    }

    .dialog .actions {
        justify-content: center !important;
        margin-top: 4rem;
    }

    .dialog .heading h2, .dialog .heading p {
        width: 100%;
        text-align: left;
    }

    .dialog .heading h2 {
        padding: 2rem 2rem 2rem 3rem;
    }

    .dialog .heading p {
        padding: 0 3rem 2rem 3rem;
    }

    .close-btn {
        color: white;
        background-color: #8F9EA6;
        padding: 1.5rem;
        width: 100%;
    }

    .content {
        flex: 1;
        padding: 1.5rem;
        border: 1px solid #eef0f1;
        overflow-y: auto;
        height: calc(100vh - 45rem);
    }

    .form-button {
        text-align: center;
    }
}
</style>
