<template>
    <div>
        <v-flex
            class="px-4">
            <p
                class="py-2">
                As the Support button on the Nav Bar redirects directly to BuyerBridge support, you can disable it
                for users who access your Organization page.
            </p>
            <v-layout
                row
                justify-center
                class="pb-3">
                <img
                    alt="image of support button in nav bar"
                    src="/img/support-nav.png">
            </v-layout>
            <v-layout
                id="switch"
                align-center
                justify-center>
                <v-layout
                    class="pr-2"
                    justify-end>
                    <v-label id="font" />
                </v-layout>
                <v-switch
                    v-model="localConfig[orgConfiguration.SUPPORT_BUTTON_DISABLED]"
                    :true-value="false"
                    :false-value="true"
                    color="info"
                    :class="localConfig[orgConfiguration.SUPPORT_BUTTON_DISABLED] ? 'v-input--reverse' : null">
                    <template
                        v-if="localConfig[orgConfiguration.SUPPORT_BUTTON_DISABLED]"
                        #label>
                        Hide
                    </template>
                    <template
                        v-else
                        #label>
                        Show
                    </template>
                </v-switch>
            </v-layout>
        </v-flex>
    </div>
</template>

<script>
import orgConfiguration from '@/helpers/organizations/configuration';

export default {
    name: 'SupportVisibility',
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            orgConfiguration,
            localConfig: { ...this.config },
        };
    },
    watch: {
        localConfig: {
            deep: true,
            handler(newVal) {
                this.$emit('updateConfig', { ...newVal });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.v-input--reverse .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
    }
}
</style>
