<template>
    <div>
        <styled-card>
            <template #heading>
                Organization Branding
            </template>
            <loader v-if="loading" />
            <div
                v-if="!loading && agencyHasDashboardWhiteLabelAccess"
                class="branding">
                <div class="branding__images">
                    <div class="branding__title">
                        Logos
                    </div>
                    <div class="branding__images-flex">
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="primaryLogo"
                                :records="['agency_id']"
                                title="Primary"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onPrimaryAssetUploaded" />
                        </div>
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="faviconLogo"
                                :records="['agency_id']"
                                title="Favicon"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onFaviconAssetUploaded" />
                        </div>
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :darked="true"
                                :initial-image="navbarLogo"
                                :records="['agency_id']"
                                title="Navbar"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onNavbarAssetUploaded" />
                        </div>
                    </div>
                </div>
                <div class="branding__colors">
                    <div class="branding__title">
                        Colors
                    </div>
                    <div class="branding__colors-flex">
                        <div class="branding__color mb-4">
                            <editable-color-field
                                label="Primary Color"
                                :value="primaryColor"
                                :handler="setPrimaryColor" />
                            <action-button
                                v-clipboard:copy="primaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                        <div class="branding__color mb-5">
                            <editable-color-field
                                label="Secondary Color"
                                :value="secondaryColor"
                                :handler="setSecondaryColor" />
                            <action-button
                                v-clipboard:copy="secondaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="!loading"
                class="branding mt-5">
                <div class="branding__support">
                    <div class="branding__title">
                        Support Visibility
                    </div>

                    <div class="branding__images-flex">
                        <support-visibility
                            :config="config"
                            @updateConfig="handleConfigUpdate" />
                    </div>
                </div>
                <div class="branding__agreements">
                    <div class="branding__title">
                        Platform Privacy Policy and EULA (End User License Agreement)
                    </div>

                    <div style="padding: 1rem">
                        <div class="branding__colors-flex privacy-policy-terms">
                            <div class="mr-4">
                                <p class="font-weight-bold">
                                    Privacy Policy<small class="red--text ml-1">*</small>
                                </p>

                                <p>
                                    A privacy policy is a legal document that informs website visitors how their
                                    personal information is collected, used, shared, and protected when they interact
                                    with the site, outlining their rights regarding their data and how the website
                                    manages their privacy.
                                </p>

                                <div v-show="agreementsLoading">
                                    <loader
                                        label=""
                                        wrapper-class="text-xs-center p-1" />
                                </div>

                                <div v-show="!agreementsLoading">
                                    <styled-button
                                        v-if="!privacyPolicyIsSigned"
                                        class="continue-button"
                                        @click="showPrivacyPolicy = true">
                                        Review and Accept
                                    </styled-button>

                                    <a
                                        v-else
                                        class="text-left justify-start mt-4"
                                        style="display: flex"
                                        @click="showPrivacyPolicy = true">
                                        <icon
                                            name="check-circle"
                                            color="green"
                                            size="1.6rem" />

                                        <h3 class="gray-text ml-3">Accepted and Active</h3>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <p class="font-weight-bold">
                                    Terms of Use<small class="red--text ml-1">*</small>
                                </p>

                                <p>
                                    A Terms of Use agreement is a legal document that lets you set the rules of
                                    engagement with your site, explain how and why you may suspend or ban people from
                                    your service and avoid potential legal issues
                                </p>

                                <div v-show="agreementsLoading">
                                    <loader
                                        label=""
                                        wrapper-class="text-xs-center p-1" />
                                </div>
                                <div v-show="!agreementsLoading">
                                    <styled-button
                                        v-if="!termsIsSigned"
                                        class="continue-button"
                                        @click="showTerms = true">
                                        Review and Accept
                                    </styled-button>

                                    <a
                                        v-else
                                        class="text-left justify-start mt-4"
                                        style="display: flex"
                                        @click="showTerms = true">
                                        <icon
                                            name="check-circle"
                                            color="green"
                                            size="1.6rem" />

                                        <h3 class="gray-text ml-3">Accepted and Active</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div
                            class="mt-4"
                            style="text-align: end">
                            <small class="red--text">* Required</small>
                        </div>
                    </div>
                </div>
            </div>

            <agency-agreement-modal
                v-if="showPrivacyPolicy"
                :value="true"
                name="Privacy Policy"
                :with-footer-actions="!Boolean(privacyPolicyIsSigned)"
                @signedTheAgreement="(accepted) => acceptAgencyLegalAgreement(accepted, privacyPolicy.name, 'privacyPolicyIsSigned', processContent(privacyPolicy.body, agency))"
                @close="showPrivacyPolicy = false">
                <template #intro>
                    <h2>
                        Privacy Policy
                    </h2>
                    <p>
                        When white-labeling the BuyerBridge platform, it's important to ensure that your organization has its
                        own Privacy Policy and Terms of Use in place. By opting in, you'll agree to use our provided Privacy Policy
                        and Terms of Use templates, customized with your organization’s name. These documents will reflect your
                        company, not BuyerBridge, ensuring compliance and transparency for your users.
                    </p>
                </template>

                <template #content>
                    <div
                        v-if="privacyPolicyIsSigned"
                        class="content">
                        <div v-html="signedPrivacyPolicyDoc" />
                    </div>

                    <div
                        v-else
                        class="content">
                        <div v-html="processContent(privacyPolicy.body, agency)" />
                    </div>
                </template>
            </agency-agreement-modal>

            <agency-agreement-modal
                v-if="showTerms"
                :value="true"
                name="Terms of Use"
                :with-footer-actions="!Boolean(termsIsSigned)"
                @signedTheAgreement="(accepted) => acceptAgencyLegalAgreement(accepted, terms.name, 'termsIsSigned', processContent(terms.body, agency))"
                @close="showTerms = false">
                <template #intro>
                    <h2>
                        Terms of Use
                    </h2>
                    <p>
                        When white-labeling the BuyerBridge platform, it's important to ensure that your organization has its
                        own
                        Privacy Policy and Terms of Use in place. By opting in, you'll agree to use our provided Privacy Policy
                        and
                        Terms of Use templates, customized with your organization’s name. These documents will reflect your
                        company,
                        not BuyerBridge, ensuring compliance and transparency for your users.
                    </p>
                </template>

                <template #content>
                    <div
                        v-if="termsIsSigned"
                        class="content">
                        <div v-html="signedTermsDoc" />
                    </div>

                    <div
                        v-else
                        class="content">
                        <div v-html="processContent(terms.body, agency)" />
                    </div>
                </template>
            </agency-agreement-modal>
        </styled-card>
    </div>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import AssetUpload from '@/components/globals/AssetUpload';
import Loader from '@/components/globals/Loader';
import EditableColorField from '@/components/globals/fields/EditableColorField';
import { mapActions, mapGetters, mapState } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import SupportVisibility from '@/components/globals/OrganizationSettingsSlideout/partials/SupportVisibility.vue';
import StyledButton from '@/components/globals/StyledButton.vue';
import AgencyAgreementModal from '@/components/resellers/settings/partials/AgencyAgreementModal.vue';
import Icon from '@/components/globals/Icon.vue';
import { SET_AGENCY_PRIVACY_POLICY_IS_SIGNED, SET_AGENCY_TERMS_IS_SIGNED } from '@/store/mutation-types';
import organizationsSettings from '@/mixins/organizations-settings';

export default {
    name: 'OrganizationBranding',
    components: {
        Icon,
        AgencyAgreementModal,
        StyledButton,
        SupportVisibility,
        Loader,
        AssetUpload,
        StyledCard,
        EditableColorField,
        ActionButton
    },
    mixins: [ organizationsSettings ],
    data() {
        return {
            primaryColor: null,
            secondaryColor: null,
            primaryLogo: null,
            faviconLogo: null,
            navbarLogo: null,
            loading: true,
            config: {},
            showTerms: false,
            showPrivacyPolicy: false,
            privacyPolicy: {},
            terms: {},
            agreementsLoading: true,
            signedPrivacyPolicyDoc: null,
            signedTermsDoc: null,
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            privacyPolicyIsSigned: (state) => state.agency.agencyPrivacyPolicyIsSigned,
            termsIsSigned: (state) => state.agency.agencyTermsIsSigned
        }),
        ...mapGetters([
            'agencyHasDashboardWhiteLabelAccess'
        ]),
    },
    watch: {
        agency: {
            // This will let Vue know to look inside the array
            deep: true,

            async handler() {
                try {
                    await this.$nextTick();

                    this.loading = true;
                    this.agreementsLoading = true;

                    await this.getAgencyBranding();
                    this.signedPrivacyPolicyDoc = (await this.getAgencyPrivacyPolicy(this.agency.id))?.document || null;
                    this.signedTermsDoc = (await this.getAgencyTerms(this.agency.id))?.document || null;

                    await this.$nextTick();
                } catch (error) {
                    console.error('Error during agency handler:', error);
                } finally {
                    this.agreementsLoading = false;
                    this.loading = false;
                }
            },
        },
    },
    mounted() {
        this.config = {
            ...this.agency.configuration
        };
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch('updateCurrentAgency');
        await this.getAgencyBranding();
        await this.getLegalAgreements('privacyPolicy', 'agency_legal_agreement_privacy_policy');
        await this.getLegalAgreements('terms', 'agency_legal_agreement_terms');
        this.signedPrivacyPolicyDoc = (await this.getAgencyPrivacyPolicy(this.agency.id))?.document || null;
        this.signedTermsDoc = (await this.getAgencyTerms(this.agency.id))?.document || null;
        this.agreementsLoading = false;
        this.config = {
            ...this.agency.configuration
        };
        this.loading = false;
    },
    methods: {
        ...mapActions([
            'updateOrganizationConfiguration',
            'getAgencyPrivacyPolicy',
            'getAgencyTerms'
        ]),
        async getAgencyBranding() {
            this.primaryColor = this.agency.white_label_config?.theme?.primary_color || '#808080';
            this.secondaryColor = this.agency.white_label_config?.theme?.secondary_color || '#808080';
            this.primaryLogo = this.agency.white_label_config?.asset_urls?.small_logo ?? null;
            this.faviconLogo = this.agency.white_label_config?.asset_urls?.favicon ?? null;
            this.navbarLogo = this.agency.white_label_config?.asset_urls?.navbar_logo ?? null;
        },
        setPrimaryColor(value) {
            this.primaryColor = value;
            this.updateWhiteLabelConfig();
        },
        setSecondaryColor(value) {
            this.secondaryColor = value;
            this.updateWhiteLabelConfig();
        },
        onPrimaryAssetUploaded(assetUrl) {
            this.primaryLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        onFaviconAssetUploaded(assetUrl) {
            this.faviconLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        onNavbarAssetUploaded(assetUrl) {
            this.navbarLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        async updateWhiteLabelConfig() {
            const config = this.agency.white_label_config ?? {};
            const assetUrls = config.asset_urls ?? {};
            const theme = config.theme ?? {};

            if (this.primaryLogo != null) {
                assetUrls.small_logo = this.primaryLogo;
            }
            if (this.faviconLogo != null) {
                assetUrls.favicon = this.faviconLogo;
            }
            if (this.navbarLogo != null) {
                assetUrls.navbar_logo = this.navbarLogo;
            }
            if (this.primaryColor != null) {
                theme.primary_color = this.primaryColor;
            }
            if (this.secondaryColor != null) {
                theme.secondary_color = this.secondaryColor;
            }
            if (Object.keys(assetUrls).length >= 1) {
                config.asset_urls = assetUrls;
            }
            if (Object.keys(theme).length >= 1) {
                config.theme = theme;
            }

            await this.$http.put('/agencies/' + this.agency.id, {
                white_label_config: config
            });

            this.$store.dispatch('updateCurrentAgency');
        },
        onCopy() {
            this.$flash('Color copied to clipboard!', 'green');
        },
        async handleConfigUpdate(updatedConfig) {
            this.config = updatedConfig;
            try {
                await this.updateOrganizationConfiguration({
                    organizationId: this.agency.id,
                    configuration: this.config
                });
            } catch (error) {
                console.log(error);
            }
        },
        async getLegalAgreements(docObj, docName) {
            try {
                this[docObj] = await this.$apiRepository.getLegalAgreement(docName);
            } catch (e) {
                this[docObj] = {};
            }
        },
        async acceptAgencyLegalAgreement(accepted, docName, docIsSigned, doc) {
            if (!accepted) {
                return false;
            }

            try {
                await this.$apiRepository.acceptAgencyAgreement(this.agency.id, docName, doc);

                if (docIsSigned === 'privacyPolicyIsSigned') {
                    this.$store.commit(SET_AGENCY_PRIVACY_POLICY_IS_SIGNED, true);
                    this.signedPrivacyPolicyDoc = (await this.getAgencyPrivacyPolicy(this.agency.id))?.document || null;
                }

                if (docIsSigned === 'termsIsSigned') {
                    this.$store.commit(SET_AGENCY_TERMS_IS_SIGNED, true);
                    this.signedTermsDoc = (await this.getAgencyTerms(this.agency.id))?.document || null;
                }

                this.showPrivacyPolicy = false;
                this.showTerms = false;
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.branding {
    display: flex;

    &__title {
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 5%;
    }

    &__support {
        width: 50%;
        padding: 30px 30px 30px;
    }

    &__agreements {
        width: 50%;
        padding: 30px 30px 30px;
    }

    &__agreements &__title {
        margin-left: 0;
    }

    &__images {
        width: 70%;
        padding: 30px 0 30px 30px;
    }

    &__images-flex {
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #DFDFDF;
        padding-bottom: 15px;
        padding: 0 5%;
        padding-right: 10%;
    }

    &__image {
        width: 28%;
    }

    &__colors {
        width: 30%;
        padding-left: 30px;
        padding-top: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__colors-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-right: 60px;
        padding-top: 30px;
    }

    &__color {
        display: flex;
        align-items: flex-end;
        width: 50%;

        .copy-btn {
            margin-bottom: 5px;
        }
    }
}

.privacy-policy-terms {
    flex-direction: row;
    padding: 0;
}
</style>
