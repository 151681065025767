import { format } from 'date-fns';

export default {
    methods: {
        processContent(content, agency) {
            if  (!content) {
                return false;
            }
            return content
                .replace(/{{agency_name}}/g, agency.name)
                .replace(/{{agency_url}}/g, agency.url ?? '')
                .replace(/{{todays_date}}/g, format(new Date(), 'MM/DD/YY'));
        },
    }
};
